import "../Scss/Styles.scss";

import * as Api from "../JavaScript/api";
import "../JavaScript/lightbox";
import * as Search from "../JavaScript/search";
import * as PushNotification from "../JavaScript/pushnotification"
import "../JavaScript/youtube";
import * as Ui from "../JavaScript/ui";

const urlParams = new URLSearchParams(window.location.search);

window.onscroll = function () {
	Ui.toggleScrollToTopButton();
	Ui.handleNavigationBar();
	PushNotification.togglePushNotificationButton();
};

window.onresize = function () {
	if (window.innerWidth > 991) {
		Search.clearCompletionDesktop();
	}
	PushNotification.setOffcanvasClass();
}

document.addEventListener('DOMContentLoaded', () => {
	Ui.handleNavigationBar();
	Ui.likesIconRender();
	Api.createView();
	PushNotification.setOffcanvasClass();
	if (urlParams.has('search')) {
		Search.toggleSearchForm(true);
	}

	if (typeof (custom_data) !== 'undefined' && typeof (dataLayer) !== 'undefined') {
		dataLayer.push(custom_data);
	}
});
